import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { FileWordOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

/**
 * @param name 路由名称, 必须设置,且不能重名
 * @param meta 路由元信息（路由附带扩展信息）
 * @param redirect 重定向地址, 访问这个路由时,自定进行重定向
 * @param meta.disabled 禁用整个菜单
 * @param meta.title 菜单名称
 * @param meta.icon 菜单图标
 * @param meta.keepAlive 缓存该路由
 * @param meta.sort 排序越小越排前
 *
 * */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/article',
    name: 'article',
    redirect: '/article/index',
    component: Layout,
    meta: {
      title: '文章管理',
      icon: renderIcon(FileWordOutlined),
      sort: 2,
      permissions: ['article']
    },
    children: [
      {
        path: 'index',
        name: 'article-index',
        meta: {
          title: '文章列表',
          permissions: ['article_article']
        },
        component: () => import('@/views/article/index/index.vue')
      },
      {
        path: 'articleAddOrEdit',
        name: 'article-addOrEdit',
        meta: {
          title: '添加编辑文章',
          hidden: true,
          permissions: ['article_article']
        },
        component: () => import('@/views/article/index/addOrEdit.vue')
      },
      {
        path: 'category',
        name: 'article-category',
        meta: {
          title: '文章分类',
          permissions: ['article_category']
        },
        component: () => import('@/views/article/cate/index.vue')
      },
      {
        path: 'author',
        name: 'article-author',
        meta: {
          title: '作者管理',
          permissions: ['article_article']
        },
        component: () => import('@/views/article/author/index.vue')
      }
    ]
  }
];

export default routes;
