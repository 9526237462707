import { App } from 'vue';
import moment from 'moment';
function $formatDate(date = 0, formatText = 'YYYY.MM.DD HH:mm:ss') {
  let time: any = 0;
  if (date < 1e10) {
    time = moment.unix(date);
  } else {
    time = moment(date);
  }
  return time.format(formatText);
}
/**
 * 注册全局方法 待完善
 * @param app
 */
export function setupGlobalMethods(app: App) {
  app.config.globalProperties.$formatDate = $formatDate;
}
