import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { OptionsSharp } from '@vicons/ionicons5';
import { renderIcon } from '@/utils';

/**
 * @param name 路由名称, 必须设置,且不能重名
 * @param meta 路由元信息（路由附带扩展信息）
 * @param redirect 重定向地址, 访问这个路由时,自定进行重定向
 * @param meta.disabled 禁用整个菜单
 * @param meta.title 菜单名称
 * @param meta.icon 菜单图标
 * @param meta.keepAlive 缓存该路由
 * @param meta.sort 排序越小越排前
 *
 * */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/system',
    name: 'System',
    redirect: '/system/menu',
    component: Layout,
    meta: {
      title: '系统设置',
      icon: renderIcon(OptionsSharp),
      permissions: [
        'system',
        'system_manager_list',
        'system_role_list',
        'system_menu_list',
        'system_appSetting',
        'system_addEditAppSetting'
      ],
      sort: 10
    },
    children: [
      {
        path: 'user',
        name: 'system_user',
        meta: {
          title: '管理员管理',
          permissions: ['system_manager_list']
        },
        component: () => import('@/views/system/user/user.vue')
      },
      {
        path: 'menu',
        name: 'system_menu',
        meta: {
          title: '菜单管理',
          permissions: ['system_menu_list']
        },
        component: () => import('@/views/system/menu/menu.vue')
      },
      {
        path: 'role',
        name: 'system_role',
        meta: {
          title: '角色管理',
          permissions: ['system_role_list']
        },
        component: () => import('@/views/system/role/role.vue')
      },
      {
        path: 'appSetting',
        name: 'system_appSetting',
        meta: {
          title: '小程序设置',
          permissions: ['system_appSetting']
        },
        component: () => import('@/views/system/appSetting/index.vue')
      }
      // {
      //   path: 'addEditAppSetting',
      //   name: 'system_addEditAppSetting',
      //   meta: {
      //     title: '添加/修改小程序设置',
      //     permissions: ['system_addEditAppSetting'],
      //     hidden: true
      //   },
      //   component: () => import('@/views/system/appSetting/index.vue')
      // }
    ]
  }
];

export default routes;
