import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { CarOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

/**
 * @param name 路由名称, 必须设置,且不能重名
 * @param meta 路由元信息（路由附带扩展信息）
 * @param redirect 重定向地址, 访问这个路由时,自定进行重定向
 * @param meta.disabled 禁用整个菜单
 * @param meta.title 菜单名称
 * @param meta.icon 菜单图标
 * @param meta.keepAlive 缓存该路由
 * @param meta.sort 排序越小越排前
 *
 **/
const routes: Array<RouteRecordRaw> = [
  {
    path: '/logistics',
    name: 'logistics',
    redirect: '/logistics/index',
    component: Layout,
    meta: {
      title: '物流相关',
      icon: renderIcon(CarOutlined),
      sort: 9,
      permissions: ['logistics']
    },
    children: [
      {
        path: 'index',
        name: 'express',
        meta: {
          title: '快递公司管理',
          permissions: ['logistics_index']
        },
        component: () => import('@/views/logistics/express/index.vue')
      },
      {
        path: 'expressAddOrEdit',
        name: 'express-addOrEdit',
        meta: {
          title: '添加物流公司',
          hidden: true
        },
        component: () => import('@/views/logistics/express/addOrEdit.vue')
      }
    ]
  }
];

export default routes;
