import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { AppstoreTwotone, ApartmentOutlined, YoutubeOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/app',
  //   name: 'app',
  //   component: Layout,
  //   redirect: '/app/index',
  //   meta: {
  //     sort: 1,
  //     title: '应用管理',
  //     icon: renderIcon(AppstoreTwotone),
  //     permissions: [
  //       'app',
  //       'app_index',
  //       'app_manager',
  //       'app_activityTask',
  //       'app_activityTaskAddEdit',
  //       'app_activitySignUp',
  //       'app_momentList',
  //       'app_momentCommentList',
  //       'app_living',
  //       'app_livingAddEdit'
  //     ]
  //   },
  //   children: [
  //     // {
  //     //   path: 'index',
  //     //   name: 'app_index',
  //     //   meta: {
  //     //     title: '应用列表',
  //     //     permissions: ['app_index']
  //     //   },
  //     //   component: () => import('@/views/app/index/index.vue')
  //     // },
  //     // {
  //     //   path: 'manager',
  //     //   name: 'app_manager',
  //     //   meta: {
  //     //     title: '总团长管理',
  //     //     permissions: ['app_manager']
  //     //   },
  //     //   component: () => import('@/views/app/manager/index.vue')
  //     // },
  //     {
  //       path: 'activityTask',
  //       name: 'app_activityTask',
  //       meta: {
  //         title: '活动列表',
  //         permissions: ['app_activityTask']
  //       },
  //       component: () => import('@/views/app/activityTask/index.vue')
  //     },
  //     {
  //       path: 'addEditActivityTask',
  //       name: 'app_addEditActivityTask',
  //       meta: {
  //         hidden: true,
  //         title: '添加/修改任务',
  //         permissions: ['app_addEditActivityTask']
  //       },
  //       component: () => import('@/views/app/activityTask/addEdit.vue')
  //     },
  //     {
  //       path: 'activitySignUp',
  //       name: 'app_activitySignUp',
  //       meta: {
  //         title: '报名情况',
  //         permissions: ['app_activitySignUp'],
  //         hidden: true
  //       },
  //       component: () => import('@/views/app/activitySignUp/index.vue')
  //     },
  //     {
  //       path: 'momentList',
  //       name: 'app_momentList',
  //       meta: {
  //         title: '朋友圈管理',
  //         permissions: ['app_momentList']
  //       },
  //       component: () => import('@/views/app/moment/index.vue')
  //     },
  //     {
  //       path: 'momentCommentList',
  //       name: 'app_momentCommentList',
  //       meta: {
  //         title: '朋友圈评论管理',
  //         permissions: ['app_momentCommentList']
  //       },
  //       component: () => import('@/views/app/moment/comment.vue')
  //     },
  //     {
  //       path: 'living',
  //       name: 'app_living',
  //       meta: {
  //         title: '直播管理',
  //         permissions: ['app_living']
  //       },
  //       component: () => import('@/views/app/living/index.vue')
  //     },
  //     {
  //       path: 'livingAddEdit',
  //       name: 'app_livingAddEdit',
  //       meta: {
  //         title: '新增/修改直播预告',
  //         hidden: true,
  //         permissions: ['app_livingAddEdit']
  //       },
  //       component: () => import('@/views/app/living/addEdit.vue')
  //     }
  //   ]
  // },
  {
    path: '/activityTask',
    name: 'app_activityTask',
    redirect: '/activityTask/index',
    meta: {
      sort: 7,
      icon: renderIcon(AppstoreTwotone),
      title: '活动列表',
      permissions: ['app_activityTask']
    },
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'app_activityTask',
        meta: {
          title: '活动列表',
          permissions: ['app_activityTask']
        },
        component: () => import('@/views/app/activityTask/index.vue')
      },
      {
        path: 'addEditActivityTask',
        name: 'app_addEditActivityTask',
        meta: {
          hidden: true,
          title: '添加/修改任务',
          sort: 2,
          permissions: ['app_addEditActivityTask']
        },
        component: () => import('@/views/app/activityTask/addEdit.vue')
      },
      {
        path: 'activitySignUp',
        name: 'app_activitySignUp',
        meta: {
          title: '报名情况',
          permissions: ['app_activitySignUp'],
          hidden: true
        },
        component: () => import('@/views/app/activitySignUp/index.vue')
      }
    ]
  },
  {
    path: '/momentList',
    name: 'app_momentList',
    redirect: '/momentList/index',
    meta: {
      title: '朋友圈管理',
      sort: 7,
      icon: renderIcon(ApartmentOutlined),
      permissions: ['app_momentList']
    },
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'app_momentList',
        meta: {
          title: '朋友圈信息管理',
          sort: 2,
          permissions: ['app_momentList']
        },
        component: () => import('@/views/app/moment/index.vue')
      },
      {
        path: 'momentCommentList',
        name: 'app_momentCommentList',
        meta: {
          sort: 2,
          title: '朋友圈评论管理',
          permissions: ['app_momentCommentList']
        },
        component: () => import('@/views/app/moment/comment.vue')
      }
    ]
  },
  {
    path: '/living',
    name: 'app_living',
    redirect: '/living/index',
    meta: {
      sort: 7,
      icon: renderIcon(YoutubeOutlined),
      title: '直播管理',
      permissions: ['app_living']
    },
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'app_living',
        meta: {
          sort: 2,
          title: '直播管理',
          permissions: ['app_living']
        },
        component: () => import('@/views/app/living/index.vue')
      },
      {
        path: 'livingAddEdit',
        name: 'app_livingAddEdit',
        meta: {
          title: '新增/修改直播预告',
          hidden: true,
          permissions: ['app_livingAddEdit']
        },
        component: () => import('@/views/app/living/addEdit.vue')
      }
    ]
  }
];

export default routes;
