import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { GiftOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/goods',
    name: 'goods',
    redirect: '/goods/index',
    component: Layout,
    meta: {
      title: '商品管理',
      icon: renderIcon(GiftOutlined),
      sort: 3,
      permissions: [
        'goods',
        'goods_category',
        'goods_index',
        'goods_brand',
        'goods_goodsAddOrEdit',
        'goods_goodsCategoryAddOrEdit',
        'goods_goodsBrandAddOrEdit'
      ]
    },
    children: [
      {
        path: 'index',
        name: 'goods-index',
        meta: {
          title: '商品列表',
          permissions: ['goods_index']
        },
        component: () => import('@/views/goods/index/index.vue')
      },
      {
        path: 'goodsAddOrEdit',
        name: 'goodsAddOrEdit',
        meta: {
          title: '添加编辑商品',
          hidden: true,
          permissions: ['goods_goodsAddOrEdit']
        },
        component: () => import('@/views/goods/index/addOrEdit.vue')
      },
      {
        path: 'category',
        name: 'goods-category',
        meta: {
          title: '商品分类',
          permissions: ['goods_category']
        },
        component: () => import('@/views/goods/cate/index.vue')
      },
      {
        path: 'goodsCategoryAddOrEdit',
        name: 'goodsCategoryAddOrEdit',
        meta: {
          title: '添加编辑商品分类',
          hidden: true,
          permissions: ['goods_goodsCategoryAddOrEdit']
        },
        component: () => import('@/views/goods/cate/addOrEdit.vue')
      },
      {
        path: 'brand',
        name: 'goods-brand',
        meta: {
          title: '商品品牌',
          permissions: ['goods_brand']
        },
        component: () => import('@/views/goods/brand/index.vue')
      },
      {
        path: 'goodsBrandAddOrEdit',
        name: 'goodsBrandAddOrEdit',
        meta: {
          title: '添加编辑商品品牌',
          hidden: true,
          permissions: ['goods_goodsBrandAddOrEdit']
        },
        component: () => import('@/views/goods/brand/addOrEdit.vue')
      },
      {
        path: 'sku',
        name: 'goods-sku',
        meta: {
          title: '商品Sku',
          hidden: true,
          permissions: ['goods_sku']
        },
        component: () => import('@/views/goods/sku/index.vue')
      },
      {
        path: 'goodsSkuAddOrEdit',
        name: 'goodsSkuAddOrEdit',
        meta: {
          title: '添加编辑商品Sku',
          hidden: true,
          permissions: ['goods_addEditSku']
        },
        component: () => import('@/views/goods/sku/addOrEdit.vue')
      }
    ]
  }
];

export default routes;
