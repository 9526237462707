import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { UserOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/member',
    name: 'member',
    redirect: '/member/index',
    component: Layout,
    meta: {
      title: '用户管理',
      icon: renderIcon(UserOutlined),
      sort: 4,
      permissions: [
        'member',
        'member_list',
        'member_withdraw',
        'member-doctor',
        'member-addDoctor',
        'member-editDoctor'
      ]
    },
    children: [
      {
        path: 'index',
        name: 'member-index',
        meta: {
          title: '用户列表',
          permissions: ['member_list']
        },
        component: () => import('@/views/member/member/index.vue')
      },
      {
        path: 'detail',
        name: 'member-detail',
        meta: {
          title: '用户详情',
          hidden: true,
          permissions: ['member_list']
        },
        component: () => import('@/views/member/member/detail.vue')
      },
      {
        path: 'withdraw',
        name: 'member-withdraw',
        meta: {
          title: '提现管理',
          permissions: ['member_withdraw']
        },
        component: () => import('@/views/member/withdraw/index.vue')
      }
    ]
  }
];

export default routes;
