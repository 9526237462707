import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { ProjectOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/order',
    component: Layout,
    meta: {
      sort: 5,
      title: '交易管理',
      isRoot: true,
      activeMenu: 'order',
      icon: renderIcon(ProjectOutlined),
      permissions: ['order', 'order_index', 'order_comment']
    },
    children: [
      {
        path: 'index',
        name: 'order_index',
        meta: {
          title: '订单列表',
          activeMenu: 'order_index',
          permissions: ['order_index']
        },
        component: () => import('@/views/order/order/index.vue')
      },
      {
        path: 'detail',
        name: 'order_detail',
        meta: {
          title: '订单详情',
          hidden: true,
          permissions: ['order_index'],
          activeMenu: 'order_detail'
        },
        component: () => import('@/views/order/order/detail.vue')
      },
      {
        path: 'comment',
        name: 'order_comment',
        meta: {
          title: '评价管理',
          activeMenu: 'order_comment',
          permissions: ['order_comment']
        },
        component: () => import('@/views/order/comment/index.vue')
      }
    ]
  }
];

export default routes;
