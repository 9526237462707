import { defineStore } from 'pinia';
import { store } from '@/store';
import { ACCESS_TOKEN, CURRENT_USER, IS_SCREENLOCKED } from '@/store/mutation-types';

import { getUserInfo as getUserInfoApi, logout } from '@/api/system/user';
import { storage } from '@/utils/Storage';

export interface IUserState {
  token: string;
  name: string;
  mobile: string;
  avatar: string;
  permissions: any[];
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): IUserState => ({
    token: storage.get(ACCESS_TOKEN, ''),
    mobile: '',
    name: '',
    avatar: '',
    permissions: []
  }),
  getters: {
    getToken(): string {
      return this.token;
    },
    getAvatar(): string {
      return this.avatar;
    },
    getMobile(): string {
      return this.mobile;
    },
    getName(): string {
      return this.name;
    },
    getPermissions(): [any][] {
      return this.permissions;
    }
  },
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    setName(name: string) {
      this.name = name;
    },
    setMobile(mobile: string) {
      this.mobile = mobile;
    },
    setAvatar(avatar: string) {
      this.avatar = avatar;
    },
    setPermissions(permissions: any[]) {
      this.permissions = permissions;
    },
    // 登录
    async login(result: any) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(ACCESS_TOKEN, 'Bearer ' + result.token, ex);
      storage.set(CURRENT_USER, result, ex);
      storage.set(IS_SCREENLOCKED, false);
      this.setToken('Bearer ' + result.token);
      this.setName(result.name);
      this.setAvatar(result.avatar);
      this.setMobile(result.mobile);
    },

    // 获取用户信息
    async getInfo() {
      const result = await getUserInfoApi();
      if (result.permissions && result.permissions.length) {
        const permissionsList = result.permissions;
        this.setPermissions(permissionsList);
        this.setName(result.name);
        this.setAvatar(result.avatar);
        this.setMobile(result.mobile);
      } else {
        throw new Error('getInfo: permissionsList must be a non-null array !');
      }
      this.setAvatar(result.avatar);
      return result;
    },

    // 登出
    async logout() {
      await logout();
      this.setPermissions([]);
      this.setName('');
      this.setAvatar('');
      this.setMobile('');
      storage.remove(ACCESS_TOKEN);
      storage.remove(CURRENT_USER);
    }
  }
});

// Need to be used outside the setup
export function useUser() {
  return useUserStore(store);
}

//是否有指定权限
export function hasPermission(permission: string) {
  const permissions = useUserStore().getPermissions;
  let r = false;
  permissions.forEach((value: any) => {
    if (value?.value === permission) {
      r = true;
    }
  });
  return r;
}
