import { http } from '@/utils/http/axios';
import { hashSHA256 } from '@/utils/index';

export interface BasicResponseModel<T = any> {
  code: number;
  message: string;
  result: T;
}

export interface BasicPageParams {
  pageNumber: number;
  pageSize: number;
  total: number;
}

/**
 * @description: 获取用户信息
 */
export function getUserInfo() {
  return http.request({
    url: '/manage/user/getInfo',
    method: 'POST'
  });
}

/**
 * @description: 用户登录
 */
export function login(params: any) {
  // 更新参数中的密码为哈希后的密码
  params.password = hashSHA256(params.password);

  // 发送请求
  return http.request<BasicResponseModel>(
    {
      url: '/login/login',
      method: 'POST',
      params
    },
    {
      isTransformResponse: false
    }
  );
}

/**
 * @description: 用户登出
 */
export function logout() {
  return http.request({
    url: '/login/logout',
    method: 'POST'
  });
}

export function getUserList(params: any) {
  return http.request({
    url: '/manage/user/list',
    method: 'POST',
    params
  });
}

export function listAll() {
  return http.request({
    url: '/manage/user/listAll',
    method: 'POST'
  });
}

export function addUser(params: any) {
  params.password = hashSHA256(params.password);
  return http.request({
    url: '/manage/user/add',
    method: 'POST',
    params
  });
}

export function editUser(params: any) {
  if (params.password) {
    params.password = hashSHA256(params.password);
  }
  return http.request({
    url: '/manage/user/edit',
    method: 'POST',
    params
  });
}

export function editProfile(params: any) {
  if (params.password) {
    params.password = hashSHA256(params.password);
  }
  return http.request({
    url: '/manage/user/editProfile',
    method: 'POST',
    params
  });
}
