import { RouteRecordRaw } from 'vue-router';
import { Layout } from '@/router/constant';
import { LayoutOutlined } from '@vicons/antd';
import { renderIcon } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/position',
    name: 'position',
    redirect: '/position/index',
    component: Layout,
    meta: {
      title: '展示位管理',
      icon: renderIcon(LayoutOutlined),
      sort: 4,
      permissions: ['position', 'position_index', 'position_content']
    },
    children: [
      {
        path: 'index',
        name: 'position-index',
        meta: {
          title: '展示位列表'
        },
        component: () => import('@/views/position/index/index.vue')
      },
      {
        path: 'positionAddOrEdit',
        name: 'positionAddOrEdit',
        meta: {
          title: '添加编辑商品',
          hidden: true
        },
        component: () => import('@/views/position/index/addOrEdit.vue')
      },
      {
        path: 'content',
        name: 'position-content',
        meta: {
          title: '展示内容',
          hidden: true
        },
        component: () => import('@/views/position/content/index.vue')
      },
      {
        path: 'positionContentAddOrEdit',
        name: 'positionContentAddOrEdit',
        meta: {
          title: '添加编辑展示内容',
          hidden: true
        },
        component: () => import('@/views/position/content/addOrEdit.vue')
      }
    ]
  }
];

export default routes;
