import { http } from '@/utils/http/axios';

/**
 * @description: 根据用户id获取用户菜单
 */
export function adminMenus() {
  return http.request({
    url: '/menus',
    method: 'post'
  });
}

/**
 * 获取tree菜单列表
 * @param params
 */
export function getMenuList(params?: any) {
  return http.request({
    url: '/manage/menu/list',
    method: 'post',
    params
  });
}

export function addMenu(params: any) {
  return http.request({
    url: '/manage/menu/add',
    method: 'post',
    params
  });
}

export function editMenu(params: any) {
  return http.request({
    url: '/manage/menu/edit',
    method: 'post',
    params
  });
}

export function delMenu(params: any) {
  return http.request({
    url: '/manage/menu/del',
    method: 'post',
    params
  });
}
